import { CalendarCategory } from '../../../types/domain/calendar/constants';
import {
  Column,
  countryColumn,
  customSectorColumn,
  issuerFirmNameColumn,
  leftLeadColumn,
  offeringTypeColumn,
  postponedDateColumn,
  priceRangeLowColumn,
  regionColumn,
  secondarySharesColumn,
  sectorColumn,
  sellingRestrictionColumn,
  sizeDollarsColumn,
  sizeLocalCurrColumn,
  sizeSharesColumn,
  tickerColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
} from '../model/offeringListColumns';
import BaseDatalabCalendar, { TabProps } from './BaseDatalabCalendar';

const calendarCategory = CalendarCategory.POSTPONED;

export const columnsConfig: Column[] = [
  issuerFirmNameColumn,
  tickerColumn,
  regionColumn,
  countryColumn,
  sellingRestrictionColumn,
  sectorColumn,
  customSectorColumn,
  offeringTypeColumn,
  postponedDateColumn,
  sizeLocalCurrColumn,
  sizeDollarsColumn,
  sizeSharesColumn,
  secondarySharesColumn,
  priceRangeLowColumn,
  leftLeadColumn,
  totalBookRunnersColumn,
  totalNonBookRunnersColumn,
];

export const staticColumns: Column[] = [issuerFirmNameColumn, tickerColumn];

export const defaultVisibleColumns = columnsConfig
  .filter(
    c => ![secondarySharesColumn, totalBookRunnersColumn, totalNonBookRunnersColumn].includes(c)
  )
  .map(c => c.field);

export const defaultSortModel = { orderBy: 'issuer.name', orderByType: 'asc' as const };

export const tabConfig = {
  calendarCategory: calendarCategory,
  defaultGroupBy: 'attributes.postponedDate',
  defaultGroupByOrderType: 'desc' as const,
  defaultOrderBy: defaultSortModel.orderBy,
  defaultOrderByType: defaultSortModel.orderByType,
  columnsConfig: columnsConfig,
  staticColumns: staticColumns,
  defaultVisibleColumns: defaultVisibleColumns,
};

const PostponedOfferingsCalendar = (props: TabProps) => {
  return <BaseDatalabCalendar {...props} {...tabConfig} />;
};

export default PostponedOfferingsCalendar;

import { OrderByType } from '../../../../common/components/table/types';
import { OfferingSortInput, SortEnumType } from '../../../../graphql/__generated__';

export type OrderProps = { orderBy: string; orderByType: OrderByType };

const OrderByTypeToGraphQlMap: Record<OrderByType, SortEnumType> = {
  asc: SortEnumType.Asc,
  desc: SortEnumType.Desc,
  descWithNullFirst: SortEnumType.Desc,
};

export function getGraphqlOrder({
  primaryOrder,
  secondaryOrder,
}: {
  primaryOrder: OrderProps;
  secondaryOrder: OrderProps;
}) {
  const primarySort = getOfferingSort(handleNullsFirstAscendingOrder(primaryOrder));
  const secondarySort = getOfferingSort(secondaryOrder);
  return [primarySort, secondarySort];
}

export function getOfferingSort({ orderBy, orderByType }: OrderProps): OfferingSortInput {
  const direction = OrderByTypeToGraphQlMap[orderByType];
  return orderBy
    .split('.')
    .reverse()
    .reduce((sort, key, index) => {
      if (index === 0 && key.endsWith('DisplayName')) {
        return { [key.replace('DisplayName', '')]: { displayName: direction } };
      }
      return { [key]: index === 0 ? direction : sort };
    }, {});
}

// fields with computed `*NonNull` field for asc-null-first sort order
const nonNullFields = ['pricingDate', 'attributes.pricingDate'];

function handleNullsFirstAscendingOrder(order: OrderProps): OrderProps {
  let { orderBy, orderByType } = order;
  if (nonNullFields.includes(orderBy) && orderByType === 'asc') {
    orderBy = `${orderBy}NonNull`;
  }

  return { orderBy, orderByType };
}
